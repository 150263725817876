import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import twitter from '../img/social/twitter.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <a href="#___gatsby">
          <img
            src={logo}
            alt="らんきんぐ.com"
            style={{ width: '12em', height: '12em' }}
          />
          </a>
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                    <a target="_blank" className="navbar-item" href="https://docs.google.com/forms/d/15ZTs9abc56SG1spMBk64EwH8GCqI5GzwZAAIrt3Q8d4/edit">
                      Contact
                    </a>
                    </li>
                  </ul>
                </section>
              </div>
              {/* <div className="column is-4 social">
                <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
